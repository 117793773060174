import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SkinnyHeader = makeShortcode("SkinnyHeader");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`What’s all this then?`}</h1>
    <p>{`First, let’s get this out of the way: we never meant to start a food blog. (And that’s not what this is!)`}</p>
    <p>{`It’s our anti-food-blog recipe repository, and answer to “Please send me that recipe!” You’ll find some of our favorite recipes re-written and adapted to reflect how we actually make them, plus a few original creations.`}</p>
    <p>{`You `}<em parentName="p">{`won’t`}</em>{` find long-winded soliloquies or keyword-stuffed prep instructions — just the ingredients and steps you need.`}</p>
    <p>{`So browse away, let us know what you think, and happy kitchening!`}</p>
    <SkinnyHeader mdxType="SkinnyHeader">Who’s the “we” here?</SkinnyHeader>
    <p>{`We’re Conor and Nolan, a couple living, cooking, and baking together in Minneapolis. (Okay realistically, Nolan does most of the baking.)`}</p>
    <SkinnyHeader mdxType="SkinnyHeader">Food From 101?</SkinnyHeader>
    <p>{`Moving to our first apartment together in St. Paul was what kickstarted our love for cooking and spending time in the kitchen together. We’d gone from a tiny no-counters kind of place to a dreamy kitchen with a big island, lots of windows, and all the space we needed.`}</p>
    <p>{`Our street address (and the end of our zip code!) was 101, we liked the idea of commemorating that special place, and the domain was available ✌︎`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      